.upload-btn{
    display: block;
    margin: 1rem auto;
    width: 100px;
    height: 40px;
    cursor: pointer;
    background-color: var(--blue);
    border: 0;
    border-radius: .5rem;
    color: white;
}
.upload-select{
    margin: 1rem;
}
.remainig-container{
    background-color:var(--red);
    padding: 1rem;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
}
.remainig-container p{
    color: white;
}