.order-details{
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    padding: 1rem;
    background-color: white;
}
.order-label{
    font-weight: 500;
}
.order-content{
    color: var(--gray-text);
    margin-top: .4rem;
}