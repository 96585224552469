
/* view prescription */
.vp-container{
    background-color: white;
    border-radius: 1rem;
    padding: 1rem;
    position: relative;
}
.vp-inner{
    display: flex;
    margin-top: 1rem;
    gap: 1rem;
}
.vp-inner img{
    border: 1px solid var(--border);
    border-radius: .5rem;
    width: 150px;
    height: 150px;
    padding: .5rem;
    object-fit: contain;
}