.edit-container {
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); */
    grid-template-columns: auto auto;
    background-color: white;
    width: 100%;
}
.edit-row{
    display: flex;
    align-items: center;
    margin: 1rem;
}
.edit-row label{
    /* width: 10ch; */
    flex: .4;
    white-space: nowrap;
}
.edit-input{
    flex: 1;
    margin-left: 1rem;
    border: 1px solid var(--border);
    border-radius: .4rem;
    padding: .4rem .8rem;
    outline: none;
}
.edit-input .text-area{
    width: 100%;
    resize: none;
    font-family: inherit;
    outline: inherit;
    padding: 0;
    border: 0;
}
.edit-input select{
    width: 100%;
    border: 0;
    outline: none;
    cursor: pointer;
}
.form-image{
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.upload-button{
    background-color: white;
    border: 1px solid var(--blue);
    padding: 2px 16px ;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 1rem;
    /* box-shadow: 2px 2px 20px #F1F1F1; */
}
.button-submit{
    display: block;
    margin: 1rem auto;
    background-color: var(--blue);
    border: 0px;
    border-radius: 50px;
    color: white;
    /* padding: .5rem 2rem; */
    width: 120px;
    height: 40px;
    cursor: pointer;
}
.button-submit:focus{
    background-color: var(--blue-light);
}