.full-image{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
.close-image{
    position: absolute;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    top: 1rem;
    right: 1rem;
    z-index: 1;
}