.product-view-container{
    background-color: white;
    padding: 1rem;
    border-radius: .5rem;
    position: relative;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
}
.product-details-title{
    border-bottom: 1px solid var(--border);
    padding-bottom: .5rem;
    margin-bottom: 1rem;
}
.product-view-container img{
    width: 150px;
    height: 150px;
    object-fit: contain;
    border: 1px solid var(--border);
    border-radius: .5rem;
}
.title{
    margin-bottom: .5rem;
}
.desc{
    margin-bottom: .5rem;
    max-width: 25ch;
    color: var(--gray-text);
}