.banner-container{
    padding: 1rem;
    list-style-type: none;
}
.banner-row{
    display: flex;
    gap: 1rem;
}
.banner-image{
    flex: 1;
    height: 130px;
    object-fit: cover;
    margin: .5rem 0px;
    cursor: pointer;
}
.add-banner{
    display: flex;
    width: 80%;
    height: 100px;
    margin: auto;
    margin-bottom: 1rem;
    background-color: white;
    border: 1px solid var(--border);
    border-radius: .5rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
/* add-banner styles */
.ab-container{
    background-color: white;
    padding: 1rem;
    /* border-radius: 1rem; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}
.ab-title{
    text-align: center;
    border-bottom: 1px solid var(--border);
    padding-bottom: .5rem;
}
.selected-b-img{
    height: 250px;
}
.ab-button{
    width: 100px;
    height: 40px;
    margin: auto;
    background-color: var(--blue);
    border: 0;
    border-radius: .5rem;
    color: white;
    cursor: pointer;
}