.container {
    width: clamp(700px, 75%, 1200px);
    margin: auto;
}
.logout-button{
    position: absolute;
    width: 25px;
    height: 25px;
    padding: 4px;
    border-radius: .3rem;
    top: 1rem;
    right: 1rem;
    background-color: var(--red);
    fill: white;
    cursor: pointer;
}
.page-container {
    background-color: var(--gray);
    padding: 1rem;
    box-sizing: border-box;
}

/* for full screen fixed container */
.full-screen-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: hsla(0, 0%, 0%, 0.4);
    position: fixed;
    z-index: 1;
    /* height: 100%; */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.page-title {
    text-align: center;
}

.page-inner-container {
    border: 1px solid var(--border);
    margin: 1rem;
    box-shadow: 2px 2px 10px #D1D1D1;
    box-sizing: border-box;
    /* text-align: center; */
    overflow-x: auto;
}

.search-input {
    display: block;
    border: 1px solid var(--border);
    width: 30%;
    border-radius: .5rem;
    padding: .5rem 1rem;
    margin: 1rem auto;
}

/* table  */
table {
    /* overflow-x: auto; */
    width: 100%;
    /* flex: 0.2; */
    background-color: white;
    border-collapse: collapse;
}

thead {
    height: 60px;
    background-color: var(--tableHead);
}

.table-row:nth-child(even) {
    background-color: var(--gray);
}

td, th {
    text-align: left;
    padding: .5rem 1rem;
    text-align: center;
    width: 10%;
}

tbody tr {
    height: 60px;
}

th {
    color: white;
    font-weight: 500;
}

.table-item {
    text-align: start;
    width: 30%;
    /* background-color: aqua; */
}

.table-item div {
    display: flex;
    align-items: center;
}

.table-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    cursor: pointer;
    border-radius: .5rem;
}

.item-name {
    margin: 0px 20px;
    min-width: 20ch;
    max-width: 30ch;
}
.item-details{
    min-width: 20ch;
    max-width: 30ch;
}
/* orders */
.order-product-name {
    text-align: start;
    max-width: 30ch;
}

.order-product-name:hover {
    color: var(--red);
    cursor: pointer;
}
/* details button */
.view-details{
    border: 2px solid var(--blue);
    background-color: white;
    border-radius: .5rem;
    color: var(--blue);
    padding: .4rem .8rem;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
}
.table-date {
    min-width: 9ch;
}

.table-action {
    display: flex;
    align-items: center;
    gap: 6px;
}

.table-action-button {
    padding: 4px;
    background-color: white;
    border: 1px solid var(--border);
    cursor: pointer;
    display: flex;
}

.table-action-complete {
    padding: 4px 8px;
    background-color: var(--red);
    color: white;
    border: 0;
    border-radius: .3rem;
    cursor: pointer;
}