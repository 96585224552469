@import url('https://fonts.googleapis.com/css2?family=Syne:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import '/src/css/var.css';

* {
  margin: 0;
  padding: 0;
  color: var(--black);
}
html{
  min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Syne', sans-serif;
}

body, input {
  font-family: 'Roboto', sans-serif;
  /* box-sizing: border-box; */
}