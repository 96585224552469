.nav-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: var(--blue);
    height: 10vh;
}

.nav-link {
    padding: 1rem;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.nav-name {
    color: var(--black);
    color: white;
}

/* expand container */
.expand-container {
    display: none;
    position: absolute;
    box-shadow: 2px 2px 10px var(--border);
    z-index: 1;
    padding: 1rem;
    background-color: white;
}

.expand-sub {
    display: flex;
    flex-direction: column;
}

.expand-name {
    text-decoration: none;
    color: var(--black);
    font-size: .9rem;
    padding: .5rem 0;
}

/* pseudo classes */
.nav-link:hover {
    background-color: var(--blue-light);
}

.drop-down:hover .expand-container {
    display: block;
}

.expand-name:hover {
    color: var(--red);
}