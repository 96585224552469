.loginContainer {
    background-color: var(--blue);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.loginForm{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: white;
    padding: 2rem;
    border-radius: 1rem;
    width: 300px;
}
.loginInput{
    border: 1px solid var(--border);
    border-radius: 1rem;
    padding: 1rem;
    /* font-size: 1rem; */
}
.loginButton{
    background-color: var(--blue);
    color: white;
    cursor: pointer;
}
.loginButton:focus{
    background-color: var(--blue-light);
}